<template>
  <div class="trouble-flow">
    <van-nav-bar left-arrow title="改善流程" @click-left="$router.back()" />
    <van-tabs v-model="active" swipeable sticky animated>
      <van-tab title="流程图" name="1">
        <trouble-process :trouble="trouble" />
      </van-tab>
      <van-tab title="改善过程记录" name="2">
        <trouble-record :trouble="trouble" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import TroubleRecord from "@/views/trouble/trouble-fix/components/TroubleRecord";
import TroubleProcess from "@/views/trouble/trouble-fix/components/TroubleProcess";
export default {
  name: "TroubleFlow",
  components: { TroubleProcess, TroubleRecord },
  data() {
    return {
      active: "1",
      trouble: false
    };
  }
};
</script>
