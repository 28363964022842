<template>
  <div class="trouble-process">
    <div v-for="(item, key) in processMap" :key="key" class="process-item">
      <div
        :class="['label', item.current && 'current-label']"
        @click="onLabelClick(item, key)"
      >
        {{ item.label }}
      </div>
      <process-arrow
        v-if="+key !== 4"
        :is-active="item.ended"
        style="margin: 0 auto"
      />
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import ProcessArrow from "@/views/workticket/components/ProcessArrow";
import { processMap } from "../utils/constant";
import cloneDeep from "lodash/cloneDeep";
import { operateDialog } from "@/utils";
import { getProcessPerson } from "@/api/psm/trouble";
import { getProcessPerson as getProcessPerson2 } from "@/api/improve/improve";

//   { label: "待提交", value: "0" },
//   { label: "待重新提交", value: "9" },
//   { label: "待审批", value: "1" },
//   { label: "待接收", value: "7" },
//   { label: "待整改", value: "2" },
//   { label: "待延期审批", value: "3" },
//   { label: "待验收", value: "4" },
//   { label: "已完成", value: "5" },
//   { label: "已取消", value: "8" }

const map = { 0: 0, 9: 0, 1: 1, 7: 2, 2: 3, 3: 1, 4: 4, 5: 4, 8: 0 };

export default {
  name: "TroubleProcess",
  props: ["trouble"],
  components: { ProcessArrow },
  data() {
    return {
      troubleId: "",
      status: "",
      processMap: cloneDeep(processMap)
    };
  },
  created() {
    const { id, status } = this.$route.query;
    if (!id || !status) {
      Dialog.confirm({
        title: "提示",
        message: "非正常进入页面",
        closeOnPopstate: true
      }).finally(() => this.$router.back());
    } else {
      this.troubleId = id;
      this.currentStatus = status;
      this.processRenderMap();
    }
  },
  methods: {
    processRenderMap() {
      let index = map[this.currentStatus];
      this.processMap[index].current = true;
      while (--index >= 0) {
        this.processMap[index].ended = true;
      }
    },
    async onLabelClick(row, status) {
      try {
        let personList = [];
        const params = {
          status,
          troubleId: this.troubleId,
          orgCode: this.userInfo.orgCode
        };
        if (this.trouble) {
          personList = (await getProcessPerson(params)) || [];
        } else {
          personList = (await getProcessPerson2(params)) || [];
        }
        if (!personList.length) {
          return Toast("人员还未确定!");
        }
        const person = personList
          .map(x => x.userName + (x.time ? ` (${x.time})` : ""))
          .join("、");
        await operateDialog({
          type: "-",
          title: "人员显示",
          showCancelButton: false,
          message: `${row.label}人：${person}`
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss">
.trouble-process {
  margin: 36px 74px 0;
  .process-item {
    .label {
      width: 228px;
      height: 48px;
      border-radius: 4px;
      text-align: center;
      line-height: 48px;
      font-size: 14px;
      font-weight: 400;
      color: #2e2e4d;
      background: #dcebff;
      border: 1px solid rgba(22, 118, 255, 0.5);
      font-family: PingFangSC-Regular, PingFang SC, serif;
    }
    .current-label {
      background: #1676ff;
      color: #fff;
    }
  }
}
</style>
