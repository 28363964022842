<template>
  <div class="trouble-record">
    <van-steps
      :active="-1"
      :class="['trouble-flow-steps', dialog && 'dialog']"
      direction="vertical"
    >
      <div>
        <van-step v-for="(item, idx) in records" :key="idx">
          <h3 v-if="+item.stage === 3">
            <span>{{ item.stageName + "-" + item.operateName }}</span>
            <span v-if="+item.operate !== 6"
              >(申请日期：{{ item.applyDelayDate || "1900-01-01" }})</span
            >
          </h3>
          <h3 v-else-if="+item.stage === 4">{{ item.stageName }}</h3>
          <h3 v-else>{{ item.stageName + " - " + item.operateName }}</h3>
          <div v-if="item.content" class="content-and-image">
            <p :class="['is-spread']">
              {{ item.content }}
            </p>
            <div
              v-if="item.files && item.files.length"
              :class="['feature-image', 'feature-image-spread']"
            >
              <i class="iconfont iconfont-tupian" @click="onImageClick(item)" />
            </div>
          </div>
          <div class="intro">
            <span>
              <van-icon
                class-prefix="iconfont"
                class="intro-icon"
                name="yonghu1"
              />
              <b>{{ item.operateUserName }}</b>
            </span>
            <span>
              <van-icon
                class-prefix="iconfont"
                class="intro-icon"
                name="riqi"
              />
              <b>{{ item.time }}</b>
            </span>
          </div>
        </van-step>
        <div style="height: 40px"></div>
      </div>
    </van-steps>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { getFileUrlForFileSystem } from "@/utils/file";
import { getListTroubleRecordByTroubleId } from "@/api/psm/trouble";
import { getListTroubleRecordByTroubleId as getListRecordByTroubleId } from "@/api/improve/improve";

export default {
  name: "TroubleRecord",
  props: {
    id: String,
    trouble: {
      type: Boolean,
      default: true
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // 隐患记录
    records: [],
    troubleId: ""
  }),
  created() {
    if (!this.$route.query.id) {
      this.troubleId = this.id;
    } else {
      this.troubleId = this.$route.query.id;
    }
    this.getRecords();
    console.log(this.trouble, "trouble");
  },
  methods: {
    onImageClick(row) {
      const urls =
        row.files?.map(x => getFileUrlForFileSystem(x.id, false)) || [];
      ImagePreview(urls);
    },
    async getRecords() {
      try {
        if (this.trouble) {
          this.records = await getListTroubleRecordByTroubleId(this.troubleId);
        } else {
          this.records = await getListRecordByTroubleId(this.troubleId);
        }
      } catch (e) {
        console.log("getRecords -> e", e);
      }
    }
  }
};
</script>

<style lang="scss">
.trouble-record {
  .trouble-flow-steps {
    height: calc(100vh - 58px);
    overflow: auto;
    margin: 0 10px;
    h3 {
      color: #3b4664;
      margin-bottom: 1vh;
      font-size: 14px;
    }
    p {
      margin: 1vh 0;
      font-size: 14px;
      line-height: 24px;
      color: #aeb3c0;
    }
    .intro {
      display: flex;
      align-items: center;
      margin-top: 1vh;
      font-size: 13px;
      color: #aeb3c0;

      span {
        display: flex;
        align-items: center;
        margin-right: 18px;
        b {
          font-size: 12px;
          margin-left: 4px;
          color: #8c8f97;
        }
      }
    }
    .intro-icon {
      font-size: 16px;
      font-weight: bold;
    }
    .van-steps__items {
      height: inherit !important;
      // display: inline-block;
    }
  }
  .dialog {
    height: 100%;
    max-height: 50vh;
  }

  .trouble-flow {
    .van-step__circle {
      width: 10px;
      height: 10px;
      background-color: #c3c6d0;
      transform: translateX(0.15vw);
    }
    .van-step__line {
      width: 2px;
      background-color: #eceef2;
    }
    .van-step__title {
      h3 {
        color: #2e2e4d;
        font-weight: bold;
      }
    }
    .van-step--vertical:not(:last-child)::after {
      border: none;
    }

    .content-and-image {
      position: relative;
      .feature-image {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        width: 68px;
        font-size: 14px;
        text-align: right;
        padding-left: 5px;
        background: #fff;
        box-shadow: -5px 0 5px #fff;
        line-height: 1;
        .iconfont {
          font-size: 20px;
          margin-right: 20px;
          color: #f69b64;
        }
      }
    }
    .is-spread {
      height: auto;
      overflow: visible;
    }
    .spread {
      height: calc(3em + 24px);
      overflow: hidden;
    }
    .feature-image-is-spread {
      bottom: 6px;
    }
    .feature-image-spread {
      bottom: -5px;
    }
  }
}
</style>
