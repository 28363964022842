import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const API = apiPath.psm;
const config = apiPath.config;

import loginUtil from "@/utils/login";
const userInfo = loginUtil.getUserInfo();
const otherParams = { orgCode: userInfo?.orgCode || "" };

/**
 * 隐患级别下拉
 */
export const getSelectTroubleLevel = () =>
  axios.get(API + "/select/level/name");

// 隐患类别相关

// 查询排查类别的下拉
export const getSelectTroubleClasses = () =>
  axios.get(API + "/select/trouble/category");

// 更新隐患状态相关
/**
 * 隐患审批
 * @param {*} data
 */
export const approveTrouble = data =>
  axios.post(API + "/approve/trouble", data);

export const notTrouble = data => axios.post(`${API}/return/approves`, data);

/**
 * 隐患审批通过后增加HSE积分
 * @param {*} troubleId
 */
export const addHsePoint = troubleId =>
  axios.post(API + "/add/trouble/hse/point/" + troubleId);

/**
 * 隐患接受
 * @param {*} data
 */
export const receiveTrouble = data =>
  axios.post(API + "/receive/trouble", data);

/**
 * 完成整改
 * @param {*} data
 */
export const finishDisposeTrouble = data =>
  axios.post(API + "/finish/dispose", data);

/**
 * 整改验收
 * @param {*} data
 */
export const checkTrouble = data => axios.post(API + "/check/trouble", data);

/**
 * 不分页请求隐患
 * @param {*} params
 */
export const getListTroubleList = params => {
  const p = { ...params, ...otherParams };
  return axios.get(API + "/list/troubles", { params: p });
};

/**
 * 存草稿，创建隐患
 * @param {*} data
 */
export const saveTrouble = data => axios.post(API + "/add/troubles", data);

/**
 * 更新隐患草稿
 * @param {*} id
 * @param {*} data
 */
export const saveTroubleById = (id, data) =>
  axios.post(API + "/update/troubles/" + id, data);

/**
 * 提交隐患信息
 * @param {*} data
 */
export const commitTrouble = data => axios.post(API + "/commit/troubles", data);

/**
 * 提交隐患（存草稿后修改提交）
 * @param {*} id
 * @param {*} data
 */
export const commitTroubleById = (id, data) =>
  axios.post(API + "/commit/troubles/" + id, data);

/**
 * 查询隐患操作记录
 * @param {*} troubleId
 */
export const getListTroubleRecordByTroubleId = troubleId =>
  axios.get(API + "/list/operate/records/" + troubleId);

/**
 * 所有退回的接口
 * @param {*} data
 */
export const refuseApproves = data =>
  axios.post(API + "/refuse/approves", data);

/**
 * 新增延期记录
 * @param {*} data
 */
export const delayTrouble = data => axios.post(API + "/add/extensions", data);

/**
 * 延期审批
 * @param {*} data
 */
export const delayApproveTrouble = data =>
  axios.post(API + "/approve/extensions", data);

/**
 * 审批弹框用到查询审批流的人员
 * @param {*} id
 * @param department
 * @param troubleLevel
 * @param orgCode
 */
export const getApprovePersonConf = (
  id,
  department = "",
  troubleLevel = "",
  orgCode = ""
) =>
  axios.get(API + "/info/approve/privilege/" + id, {
    params: { department, troubleLevel, orgCode }
  });
/**
 * 查询审批信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTroubleApproveInfoById = id =>
  axios.get(API + "/info/approve/trouble/" + id);

/**
 *  查询接收信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTroubleReceivesInfoById = id =>
  axios.get(API + "/info/receive/trouble/" + id);

/**
 * 查看验收信息
 * @param id
 * @returns {*}
 */
export const getTroubleCheckInfoById = id =>
  axios.get(API + "/info/check/trouble/" + id);

/**
 * 查看整改信息
 * @param id
 * @returns {*}
 */
export const getTroubleModifyInfoById = id =>
  axios.get(API + "/info/finish/dispose/trouble/" + id);

/**
 * 查看延时信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTroubleDelayInfoById = id =>
  axios.get(API + "/info/extensions/" + id);

/**
 * 隐患状态统计
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatisticsTroubleStatus = params =>
  axios.get(API + "/statistics/status/troubles", { params: { ...params } });

/**
 * 隐患等级统计
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatisticsTroubleLevel = params =>
  axios.get(API + "/statistics/levels/troubles", { params: { ...params } });

/**
 * 隐患整改数量
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatisticsTroubleFixNum = params =>
  axios.get(API + "/statistics/fix/troubles", { params: { ...params } });

/**
 * 隐患折线图完成率和及时率
 * @param params
 * @param type 0: 完成率; 1: 及时率
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatisticsTroubleRatios = (params, type) =>
  axios.get(API + "/statistics/troubles/finish/ratios", {
    params: { ...params, type }
  });

/**
 * 查询隐患整改状态
 */
export const getSelectTroubleStatus = () =>
  axios.get(API + "/select/dispose/status");

/**
 * 查询隐患（分页）
 * @param {*} params
 */
export const getTroubleList = params =>
  axios.get(API + "/page/troubles", { params: { ...params } });

/**
 * 查隐患
 * @returns {Promise<AxiosResponse<any>>}
 * @param troubleIds
 */
export function getListTroubleByIds(troubleIds) {
  return axios.get(API + "/base/troubles", { params: { troubleIds } });
}

/**
 * 查询隐患整改状态
 */
export const getSelectDisposeStatus = () =>
  axios.get(API + "/select/dispose/status");

/**
 * 获取所属计划
 * @param {*} params
 */
export const getSelectPlans = params =>
  // axios.get(API + "/select/available/plans", { params: { ...params } });
  axios.get(API + "/select/plans", { params: { ...params } });

/**
 * 查询隐患（修改）
 * @param {*} id
 */
export const getTroubleInfoById = id => axios.get(API + "/info/troubles/" + id);

/**
 * 是否有审批权限
 * @param {*} id
 */
export const authAllowApproves = id => axios.get(API + "/allow/approves/" + id);

/**
 * 查询排查级别
 */
export const getSelectInspectLevelTypes = () => axios.get(API + "/org/levels");

/**
 * 查看任务基本信息 根据 任务ID
 * @param taskId
 */
export const getInspectBaseInfoByTaskId = taskId =>
  axios.get(API + "/info/task/" + taskId);

/**
 * 查询分发任务列表（分页）
 * @param {*} taskId
 * @param {*} params
 */
export const getDistributeTaskRecordsByTaskId = (taskId, params) =>
  axios.get(API + "/page/distribute/task/" + taskId, {
    params: { ...params }
  });

/**
 * 排查记录状态
 */
export const getSelectInspectRecordStatusTypes = () =>
  axios.get(API + "/inspect/record/status");

/**
 * 查询排查记录（分页）
 * @param {*} params
 */
export const getInspectRecords = params =>
  axios.get(API + "/page/inspected/records", { params: { ...params } });

/**
 * 排查任务 -> 排查项内容，不含排查结果
 * @param {*} taskId
 * @param params
 */
export const getCheckItemListByTaskId = (taskId, params) =>
  axios.get(API + "/query/task/check/items/" + taskId, {
    params: { ...params }
  });

/**
 * 查询排查任务记录详细信息
 * @param {*} recordId
 */
export const getInspectRecordInfoByRecordId = recordId =>
  axios.get(API + "/info/inspected/records/" + recordId);

/**
 * 排查项与隐患解除关联
 * @param {*} id
 */
export const delTroubleToInspectById = id =>
  axios.get(API + "/delete/item/troubles/" + id);

/**
 * 发现方式下拉
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSelectFindMode = params => {
  return axios.get(`${API}/select/trouble/discovery`, params);
};

/**
 *
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const distributeTrouble = data => {
  return axios.post(`${API}/receive/trouble/next`, data);
};

export const getTroubleFixFinishHistoryById = id => {
  return axios.get(`${API}/info/trouble/history/${id}`);
};

export const getProcessPerson = params => {
  return axios.get(`${API}/trouble/process/info`, { params });
};

/**
 * 查询隐患审批、接收、验证会签临时记录
 * @param data
 * @returns {*}
 */
export const troubleApprovesTempRecord = data => {
  return axios.get(
    `${API}/info/last/trouble-approves-temp-record/${data.troubleId}`
  );
};
/**
 * 隐患会签
 * @param data
 * @returns {*}
 */
export const countersignTrouble = data => {
  return axios.post(`${API}/countersign/trouble`, data);
};
/**
 * 查询隐患审批-待处理转审节点
 * @param data
 * @returns {*}
 */
export const troubleApprovesNode = data => {
  return axios.get(
    `${API}/info/trouble-approves-node/current/${data.troubleId}`
  );
};
/**
 * 查询隐患审批-审批人转审
 * @param data
 * @returns {*}
 */
export const troubleTransfer = data => {
  return axios.post(`${API}/approve/trouble/transfer`, data);
};
/**
 * 查询隐患审批-节点转审
 * @param data
 * @returns {*}
 */
export const nodeTransfer = data => {
  return axios.post(`${API}/approve/trouble/node-transfer`, data);
};
/**
 * 查询隐患审批-节点转审
 * @param data
 * @returns {*}
 */
export const nodeCountersign = data => {
  return axios.post(`${API}/approve/trouble/node-countersign`, data);
};
/**
 * 以隐患信息为基础创建隐患经验库
 * @param troubleId
 * @returns {*}
 */
export const quickAddApproveExperience = troubleId => {
  return axios.post(`${API}/quick/add/approve-experience/${troubleId}`);
};
/**
 * 查询隐患危害类型列表
 * @param troubleId
 * @returns {*}
 */
export const queryDangerTypes = () => {
  return axios.get(`${API}/list/troubles/dangerType`);
};

/**
 * 查询隐患字典表详情
 * @param dicId
 * @returns {*}
 */
export function getTroubleDicItems(dicId) {
  return axios.get(`${API}/list/trouble-dic-items/${dicId}`);
}

/**
 * 查询配置项
 * @returns {*}
 */
export function getModuleOptionItems() {
  return axios.get(`${config}/monitoring/list/module-option-items`);
}

export const getSelectTroubleSource = params => {
  return axios.get(`${API}/list/troubles/source`, params);
};
