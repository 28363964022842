import { deepFreeze } from "@/utils";

export const passStatusData = deepFreeze([
  {
    label: "通过",
    value: "1"
  },
  {
    label: "退回",
    value: "2"
  },
  {
    label: "不是隐患",
    value: "0"
  }
]);

export const DELAY_PASS_STATUS = deepFreeze([
  {
    label: "通过",
    value: "1"
  },
  {
    label: "退回",
    value: "0"
  }
]);

export const takeInTypes = deepFreeze([
  {
    label: "接收-整改",
    value: "1"
  },
  {
    label: "接收-下发",
    value: "2"
  },
  {
    label: "拒收",
    value: "0"
  }
]);

export const checkedIdeaTypes = deepFreeze([
  {
    label: "合格",
    value: "1"
  },
  {
    label: "不合格",
    value: "0"
  }
]);

export const fixStatusTypes = deepFreeze([
  {
    label: "整改完成",
    value: 1
  },
  {
    label: "整改延期",
    value: 2
  }
]);

export const opinionOptions = deepFreeze([
  {
    label: "同意",
    value: 1
  },
  {
    label: "不同意",
    value: 0
  }
]);
// 隐患状态配置
/**
 * @key statusName 状态名称
 * @key title 详情标题展示
 * @key route 要跳转的路由
 * @key query 路由参数，主要是权限
 * @key currentStage 当前项目已经到的总阶段
 * @key currentStep 当前我应该停留的阶段
 * @key lastRoute 上一个应该处于哪个阶段，用于权限使用
 */
export const troubleStatusMap = {
  0: {
    statusName: "待提交",
    title: "隐患提交",
    route: "trouble-add",
    query: { add: false },
    currentStage: 0,
    currentStep: 0,
    lastRoute: "trouble-info"
  },
  9: {
    statusName: "待重新提交",
    title: "隐患重新提交",
    route: "trouble-add",
    query: { add: true },
    currentStage: 0,
    currentStep: 0,
    lastRoute: "trouble-info"
  },
  1: {
    statusName: "待审批",
    title: "隐患确认",
    route: "trouble-approval",
    query: { add: false },
    currentStage: 1,
    currentStep: 1,
    lastRoute: "trouble-info"
  },
  2: {
    statusName: "待整改",
    title: "隐患整改",
    route: "trouble-modify",
    query: { add: false },
    currentStage: 3,
    currentStep: 3,
    lastRoute: "trouble-receive"
  },
  3: {
    statusName: "待延期审批",
    title: "隐患延期审批",
    route: "trouble-delay-approval",
    query: { add: false },
    currentStage: 3,
    currentStep: 3,
    lastRoute: "trouble-modify"
  },
  4: {
    statusName: "待验收",
    title: "隐患验收",
    route: "trouble-check",
    query: { add: false },
    currentStage: 4,
    currentStep: 4,
    lastRoute: "trouble-modify"
  },
  5: {
    statusName: "已完成",
    title: "隐患完成",
    route: "trouble-check",
    query: { add: false },
    currentStage: 4,
    currentStep: 4,
    lastRoute: "trouble-check"
  },
  7: {
    statusName: "待接收",
    title: "隐患接收",
    route: "trouble-receive",
    query: { add: false },
    currentStage: 2,
    currentStep: 2,
    lastRoute: "trouble-approval"
  },
  8: {
    statusName: "已取消",
    title: "隐患取消",
    route: "trouble-info",
    query: { add: false },
    currentStage: 0,
    currentStep: 0,
    lastRoute: "trouble-info"
  }
};

export const clickMap = {
  0: {
    status: "",
    statusTitle: "登记",
    statusName: "待提交",
    routeName: "trouble-info",
    currentStep: 0,
    query: { add: false }
  },
  1: {
    status: "",
    statusTitle: "确认",
    statusName: "待审批",
    routeName: "trouble-approval",
    currentStep: 1,
    query: { add: false }
  },
  2: {
    status: "",
    statusTitle: "接收",
    statusName: "待接收",
    routeName: "trouble-receive",
    currentStep: 2,
    // 接收状态是 7
    query: { add: false }
  },
  3: {
    status: "",
    statusTitle: "整改",
    statusName: "待整改",
    routeName: "trouble-modify",
    currentStep: 3,
    // 待整改是 2
    query: { add: false }
  },
  4: {
    status: "",
    statusTitle: "验收",
    statusName: "待验收",
    routeName: "trouble-check",
    currentStep: 4,
    // 待验收是 4
    query: { add: false }
  }
};

export const processMap = {
  0: { label: "提交", status: 0, ended: false, current: false },
  1: { label: "审批", status: 1, ended: false, current: false },
  2: { label: "接收", status: 7, ended: false, current: false },
  3: { label: "整改", status: 2, ended: false, current: false },
  4: { label: "验收", status: 4, ended: false, current: false }
};
