<template>
  <div
    v-if="type === 'normal'"
    class="process-arrow"
    :class="[type, arrowStatus]"
  ></div>
  <div
    v-else-if="type === 'polyline'"
    class="process-arrow"
    :class="[type, arrowStatus]"
  >
    <i class="arrow"></i>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "normal"
    },
    arrowStatus: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
$normal-line-color: #c7c9d0;
$active-line-color: #1676ff;
.process-arrow {
  &.normal {
    position: relative;
    width: 10px;
    height: 38px;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      background-color: $normal-line-color;
      transform: translate3d(-50%, 0, 0);
    }
    &::after {
      position: absolute;
      content: "";
      bottom: -5px;
      left: 0;
      width: 0;
      border: 5px solid transparent;
      border-top-width: 6px;
      border-top-color: $normal-line-color;
    }
    &.active {
      &::before {
        background-color: $active-line-color;
      }
      &::after {
        border-top-color: $active-line-color;
      }
    }
  }
  &.polyline {
    position: relative;
    width: 80px;
    height: 36px;
    &.left {
      .arrow {
        position: absolute;
        border: 1px solid $normal-line-color;
        border-top: none;
        border-right: none;
        width: 74px;
        left: 0;
        &::before {
          position: absolute;
          content: "";
          left: 72px;
          top: 19px;
          width: 1px;
          height: 18px;
          background-color: $normal-line-color;
        }
        &::after {
          position: absolute;
          content: "";
          left: 68px;
          bottom: -24px;
          width: 0;
          border: 5px solid transparent;
          border-top-width: 6px;
          border-top-color: $normal-line-color;
        }
      }
    }
    &.right {
      .arrow {
        position: absolute;
        border: 1px solid $normal-line-color;
        border-top: none;
        border-left: none;
        width: 74px;
        right: 0;
        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 19px;
          width: 1px;
          height: 18px;
          background-color: $normal-line-color;
        }
        &::after {
          position: absolute;
          content: "";
          left: -4px;
          bottom: -24px;
          width: 0;
          border: 5px solid transparent;
          border-top-width: 6px;
          border-top-color: $normal-line-color;
        }
      }
    }
    .arrow {
      position: absolute;
      content: "";
      top: 0;
      right: 50%;
      width: 80px;
      height: 18px;
      border: 1px solid $normal-line-color;
      border-top: none;
      border-left: none;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 19px;
        width: 1px;
        height: 18px;
        background-color: $normal-line-color;
      }
      &::after {
        position: absolute;
        content: "";
        left: -5px;
        bottom: -24px;
        width: 0;
        border: 5px solid transparent;
        border-top-width: 6px;
        border-top-color: $normal-line-color;
      }
    }
    &.active {
      .arrow {
        border-color: $active-line-color;
        &::before {
          background-color: $active-line-color;
        }
        &::after {
          border-top-color: $active-line-color;
        }
      }
    }
  }
}
</style>
